import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Title from '../components/Title';
import Section from '../components/Section';
import Container from '../components/Container';

const error404 = props => (
  <Layout className="pageWrapper" errorPath="true">
    <SEO
      title="Divided by Design | E Pluribus Unum"
      description="Incubated by Emerson Collective and led by former New Orleans Mayor Mitch Landrieu, E Pluribus Unum is an initiative created to fulfill America’s promise of justice and opportunity for all by breaking down the barriers that divide us by race and class."
      keywords={[`e pluribus unum`]}
    />
    <Section id="error-404" className="Wrap Full__height-sec">
      <Container className="Full__height-container Container--no-padding Grid__item--center">
        <Title el="h1" className="Title--0 Title--e Title--line-2">
          Not Found
        </Title>
        <p className="fade fade-up">
          This page has been removed or has gone missing.{' '}
          <Link
            to="/"
            style={{ textDecoration: 'underline', color: 'inherit' }}
          >
            Go to the home page.
          </Link>
        </p>
      </Container>
    </Section>
  </Layout>
);

export default error404;
